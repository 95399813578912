




























import { defineComponent, computed } from '@vue/composition-api';
import { FCCartProps } from './types';

export default defineComponent({
  name: 'FCCart',
  props: {
    displayQty: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: '30px',
    },
    height: {
      type: String,
      required: false,
      default: '30px',
    },
    viewBox: {
      type: String,
      required: false,
      default: '0 0 53.052 47.174',
    },
    cartQty: {
      type: Number,
      required: false,
      default: 0,
    },
    changeColorOnHover: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props: FCCartProps) {
    const isItemInCart = computed(() => (props?.cartQty ?? 0) > 0);

    return {
      isItemInCart,
    };
  },
});
