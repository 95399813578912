<template>
  <div>
    <ul
      v-if="!isTabletUp && slides.length > 6"
      ref="timerContainer"
      class="navigation fc-flex">
      <li
        v-for="index in 5"
        :key="index"
        class="navigation__item">
        <button
          class="navigation__button"
          :class="{'navigation__button--active': index === 3}"
          type="button" />
      </li>
    </ul>
    <ul
      v-else
      ref="timerContainer"
      class="navigation"
      :class="timerClass">
      <li
        v-for="(slide, index) in slides"
        :key="slide.screenLabel"
        class="navigation__item">
        <button
          class="navigation__button"
          :class="buttonClass(index)"
          type="button"
          @click="$emit('slideSelect', index, {
            ignoreInfiniteMode: true,
          })">
          <span class="fc-screen-reader-only">Go to slide {{ slide.screenLabel }}</span>
        </button>
        <div
          aria-hidden="true"
          class="navigation__timer" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
  import {
    computed, ComputedRef, defineComponent, Ref, SetupContext, ref, onMounted,
  } from '@vue/composition-api';
  import { NavigationProps } from './types';
  import { useNavigationTimerAnimator } from './hooks/useNavigationTimerAnimator';

  type ButtonClassHandler = (index: number) => Record<string, boolean>;

  interface NavigationBindings {
    buttonClass: ComputedRef<ButtonClassHandler>;
    timerContainer: Ref<HTMLElement | undefined>;
    timerClass: Ref<Record<string, boolean>>;
    isTabletUp: Ref<boolean>;
  }

  export default defineComponent<NavigationProps, NavigationBindings>({
    name: 'Navigation',
    props: {
      slides: {
        type: Array,
        default() {
          return [];
        },
      },
      currentSlide: {
        type: Number,
        default: -1,
      },
      timerEnabled: {
        type: Boolean,
        default: false,
      },
      timerLimit: {
        type: Number,
        default: 2000,
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
    },
    setup(props: NavigationProps, context: SetupContext): NavigationBindings {
      const timerContainer = useNavigationTimerAnimator(props, context);
      const timerClass = computed<Record<string, boolean>>(() => ({
        'navigation--hidden': !!props.isHidden,
      }));
      const buttonClass = computed<ButtonClassHandler>(() => (index) => ({
        'navigation__button--active': index === props.currentSlide,
        'navigation__button--ticking': props.timerEnabled && !props.isHidden,
        'navigation__button--ticked': props.timerEnabled && index < props.currentSlide,
      }));

      const isTabletUp = ref(false);

      onMounted(() => {
        isTabletUp.value = window.matchMedia('(min-width: 1025px)').matches;
      });

      return {
        buttonClass,
        timerContainer,
        timerClass,
        isTabletUp,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .navigation {
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    display: flex;
    justify-content: center;

    &--hidden {
      @media #{$desktop} {
        display: none;
      }
    }

    &__item {
      padding: 0;
      list-style: none;
      margin: 0 0.5rem;
      position: relative;
      width: 0.7em;
      height: 0.7rem;
      border-radius: 100%;
      overflow: hidden;

      @media #{$desktop} {
        margin: 0 0.3rem;
        width: 10%;
        height: 0.25rem;
        border-radius: 0.5rem;
      }
    }

    &__button {
      border: 0;
      background: $color-dark-gray;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &--ticking {
        &.active {
          @media #{$desktop} {
            background: $color-dark-gray;
          }
        }
      }

      &--ticked {
        @media #{$desktop} {
          background: $color-blue;
        }
      }

      &--active:not(.navigation__button--ticking) {
        background: $color-blue;
      }
    }

    &__timer {
      display: none;

      @media #{$tablet-up} {
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: $color-blue;
        transform-style: preserve-3d;
        transform: translateX(0%);
      }
    }
  }
</style>
