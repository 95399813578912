





























import {
  defineComponent, ref, computed, watch, onMounted, PropType,
} from '@vue/composition-api';
import { SizeSelectorsProps } from './types';

export default defineComponent({
  name: 'SizeSelectors',
  emits: ['selectedValue'],
  props: {
    selects: {
      type: Object as PropType<SizeSelectorsProps>,
      required: true,
      default: () => ({
        key: '',
        name: '',
        options: [],
      }),
    },
  },
  setup(props, context) {
    const selectedValue = ref(props.selects.value);

    watch(selectedValue, (newVal) => {
      context.emit('selectedValue', newVal);
    });

    onMounted(() => {
      context.emit('selectedValue', selectedValue.value);
    });

    const sizeDescription = computed(() => {
      const selectedOption = props?.selects?.options.find((item) => item.key === selectedValue.value);
      return selectedOption ? selectedOption.htc : '';
    });

    return {
      sizeDescription,
      selectedValue,
      showSizeDescription: computed(() => props?.selects?.options[0]?.htc),
    };
  },
});
