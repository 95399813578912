<template>
  <div>
    <fieldset class="package-selections-container">
      <legend class="fc-heading-6 fc-text-black fc-padding-bottom-3">
        Select {{ selects.name }}
      </legend>
      <div class="package-selections-options fc-flex fc-w-full">
        <label
          v-for="option in selects.options"
          :key="option.key"
          class="fc-w-full fc-margin-top-2"
          :for="`${selects.key}_${option.key}`">
          <input
            :id="`${selects.key}_${option.key}`"
            v-model="selectedValue"
            type="radio"
            :value="option.key"
            :name="`${selects.key}_${option.key}`"
            class="package-selections-input">
          <span class="package-selections-option button-layout button-secondary button-small">{{ option.name }}</span>
        </label>
      </div>
    </fieldset>
    <p v-if="showSizeDescription" class="package-selections-option-description">
      {{ sizeDescription }}
    </p>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent, ref, computed, watch, onMounted, PropType,
  } from '@vue/composition-api';
  import { SizeSelectorsProps } from './types';

  export default defineComponent({
    name: 'SizeSelectors',
    emits: ['selectedValue'],
    props: {
      selects: {
        type: Object as PropType<SizeSelectorsProps>,
        required: true,
        default: () => ({
          key: '',
          name: '',
          options: [],
        }),
      },
    },
    setup(props, context) {
      const selectedValue = ref(props.selects.value);

      watch(selectedValue, (newVal) => {
        context.emit('selectedValue', newVal);
      });

      onMounted(() => {
        context.emit('selectedValue', selectedValue.value);
      });

      const sizeDescription = computed(() => {
        const selectedOption = props?.selects?.options.find((item) => item.key === selectedValue.value);
        return selectedOption ? selectedOption.htc : '';
      });

      return {
        sizeDescription,
        selectedValue,
        showSizeDescription: computed(() => props?.selects?.options[0]?.htc),
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

.package-selections {
  &-container {
    border: none;
    margin: 0;
    padding: 0;
  }

  &-options {
    flex-wrap: nowrap;

    label {
      margin-right: 0.5rem;

      @media #{$tablet-up} {
        flex: 50%;
      }
    }
  }

  &-input {
    // hide radio button visually
    position: absolute;
    top: auto;
    left: -20px;
  }

  &-option {
    padding: 0.5rem 1rem;

    &-description {
      color: $color-secondary-dark-gray;
      font-size: 14px;
      text-align: center;
    }
  }

  &-input:checked + .package-selections-option {
    background-color: $fc-blue-primary-800;
    color: $color-white;
    border: 1px solid $fc-blue-primary-800;
  }
}
</style>
