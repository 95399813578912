































import {
  ref, defineComponent, onMounted, Ref,
} from '@vue/composition-api';
import { FCInputProps } from './types';

interface FCInputBindings {
  inputObj: Ref<null | HTMLInputElement>;
}

export default defineComponent<FCInputProps, FCInputBindings>({
  name: 'FCInput',
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: 'text',
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    upperCaseOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    lowercaseOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup({ focus }) {
    const inputObj : Ref<null | HTMLInputElement> = ref(null);

    onMounted(() => {
      if (focus) {
        inputObj.value!.focus();
      }
    });

    return {
      inputObj,
    };
  },
});
