

















import { defineComponent, computed, PropType } from '@vue/composition-api';
import { StarSize, FiveStarsProps } from './types';

export default defineComponent({
  name: 'FiveStars',
  props: {
    totalFilled: {
      type: Number,
      default: 5,
      required: false,
    },
    size: {
      type: String as PropType<StarSize>,
      default: 'sm',
      required: false,
    },
  },
  setup(props: FiveStarsProps) {
    const isTotalValid = computed(() => (props.totalFilled ? props.totalFilled >= 0 && props.totalFilled <= 5 : false));
    const emptyStars = computed(() => (props.totalFilled ? 5 - props.totalFilled : 5));

    return {
      emptyStars,
      isTotalValid,
    };
  },
});
